import React, { useRef } from "react";
import Logo from "../../Images/Logo/nexus_logo.png";
import "../CSS_File/Navbar.css";
import { FaAlignJustify } from "react-icons/fa";
import DownloadIcon from "../../Images/Landing_Page/download_icon.png"
import { NavLink } from "react-router-dom";

const Navbar = () => {
    let toggleNavbarMenu = useRef("");
    const showAndHideNavbar = () => {
         toggleNavbarMenu.current.classList.toggle("show-navigation-section");
    }
    const hideNavbar = () => {
        toggleNavbarMenu.current.classList.remove("show-navigation-section");
    }
    return(
        <>
        <header className="sticky-top">
        <nav className="nexus-navbar">
            <div className="container nexusNavbarFirstInnerDiv">
                <div className="row justify-content-between nexusNavbarStartHere">
                    <div className="justify-button-section" onClick={showAndHideNavbar}>
                        <span><i><FaAlignJustify /></i></span>
                    </div>
                    <div className="logo-section">
                        <NavLink to={"/"} className="href"><img src={Logo} alt="Logo" className="img-fluid" /></NavLink>
                    </div>
                    <div className="navigation-section" id="navigation-section-id" onClick={hideNavbar} ref={toggleNavbarMenu}>
                        <ul>
                            <li><NavLink to={"/features"} className="href">Features</NavLink></li>
                            <li><NavLink to={"/privacy"} className="href">Privacy</NavLink></li>
                            <li><NavLink to={"/help"} className="href">Help</NavLink></li>
                            <li><NavLink to={"/blogs"} className="href">Blogs</NavLink></li>
                            <li><NavLink to={"/download"} className="href"><button className="download-btn">Download</button></NavLink></li>
                        </ul>
                    </div>
                    <div className="download-section">
                        <NavLink to={"/download"} className="href"><img src={DownloadIcon} alt="Download Icon" className="img-fluid downloadIcon" /></NavLink>
                    </div>
                </div>
            </div>
        </nav>
        </header>
        </>
    );
}

export default Navbar;