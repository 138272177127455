import React from "react";
import "../CSS_File/Footer.css"
import Logo from "../../Images/Logo/nexus_logo.png";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return(
        <>

        <section className="nexus-upper-footer">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-12 upperFooterNavbarLinksDiv">
                        <ul>
                            <li><NavLink to={"/features"} className="href">Features</NavLink></li>
                            <li><NavLink to={"/privacy"} className="href">Privacy</NavLink></li>
                            <li><NavLink to={"/blogs"} className="href">Blogs</NavLink></li>
                            <li><NavLink to={"/aboutUs"} className="href">About us</NavLink></li>
                            <li><NavLink to={"/contactUs"} className="href">Contact us</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="nexus-lower-footer">
            <div className="container">
                <div className="row justify-content-evenly">
                <div className="col-md-4 upperFooterImageDiv">
                    <NavLink to={"/"} className="href"><img src={Logo} alt="Logo" /></NavLink>
                    </div>
                    <div className="col-md-4 socia-media-icon">
                    <ul>
                            <li onClick={() => window.open('https://in.linkedin.com/')}><i><FaLinkedinIn /></i></li>
                            <li onClick={() => window.open('https://www.instagram.com/')}><i><AiFillInstagram /></i></li>
                            <li onClick={() => window.open('https://twitter.com/')}><i><FaTwitter /></i></li>
                            <li onClick={() => window.open('https://www.facebook.com/')}><i><FaFacebookF /></i></li>
                        </ul>
                    </div>
                    <div className="col-md-4 download-button-div">
                    <NavLink to={"/download"}><button className="downloadButton">Download</button></NavLink>
                    </div>
                    <div className="col-md-12 privacyPolicyAndTermsButtonDiv">
                    <p className="privacyAndTermsButton"><NavLink to={"/privacyPolicy"} className="href"><span>Privacy policy</span></NavLink> | <NavLink to={"/termsOfService"} className="href"><span>Terms of service</span></NavLink></p>
                    <p className="lastPara">&copy; All rights reserved 2023</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Footer;