import React from 'react'
import "../CSS_File/LandingPage.css"
import LandingShareImage from "../../Images/Landing_Page/landing_share_image.webp"
import LandingCreateGroups from "../../Images/Landing_Page/landing_create_groups.webp"
import LandingIncognitoGroups from "../../Images/Landing_Page/homepage_image_1.webp"
import LandingClickIcon from "../../Images/Landing_Page/charm_circle_tick.png"
import BannerImage from "../../Images/Landing_Page/landing_banner_image.webp"
import { NavLink } from 'react-router-dom'

const LandingPage = () => {
    return (
        <>
            <section className="bannerImageSectionOfLandingPage">
                <div className="container-fluid bannerImageSectionOfLandingPagesFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-5 bannerImageSectionsHeadingImageAndButton">
                            <h2>Connect<span>X</span></h2>
                            <p>Bringing you together <span className='incognitoRed'>incognito</span></p>
                            <NavLink to={"/download"} className="href"><h6>Download</h6></NavLink>
                            <div className="halfUnderlineDiv"></div>
                        </div>
                        <div className="col-md-5 bannerImageSectionsImage">
                            <img src={BannerImage} alt="Banner Image" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="secondSectionOfLandingPageStartHere" id='secondSectionOfLandingPageStartHere'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 landingPageQuotesPara">
                            <h3>Find <span>X</span>? We bet you cannot!</h3>
                            <p>
                                This is a problem the finest math teacher cannot solve. Our incognito group provides admins with the option of keeping participants anonymous to each other. None of the participants can find <span className='incognitoRed'>X</span> - they will never get to know who the other participant is unless the admin chooses so. Continue conversing with privacy.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="thirdSectionOfLandingPageStartHere">
                <div className="container-fluid">
                    <div className="row justify-content-around">
                        <div className="col-md-5 featureImageLeftSectionImage">
                            <img src={LandingShareImage} alt="Landing Page Feature" className='img-fluid' />
                        </div>
                        <div className="col-md-5 featureSectionRightHeadingAndPara">
                            <h4>Share images and files</h4>
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph otherGroupsParagraph'>Images</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph otherGroupsParagraph'>Videos</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph otherGroupsParagraph'>Documents</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <p><span><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></span><span className='ms-4 sideDetailPara'>Images</span></p>
                            <p><span><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></span><span className='ms-4 sideDetailPara'>Videos</span></p>
                            <p><span><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></span><span className='ms-4 sideDetailPara'>Documents</span></p> */}
                            <a href="/" className="href"><h6>Learn More</h6></a>
                            <div className="halfUnderlineDiv"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fourthSectionOfLandingPageStartHere">
                <div className="container-fluid fourthSectionOfLandingPageFirstInnerDiv">
                    <div className="row justify-content-around fourthSectionOfLandingPageSecondInnerDiv">
                    <div className="col-md-5 featureSectionRightHeadingAndPara">
                            <h4>Create groups</h4>
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Get your family, friends, team, together in a single group</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Send invitation link</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Messages stay private and protected because of encryption</td>
                                    </tr>
                                </tbody>
                            </table>
                            <a href="/" className="href"><h6>Learn More</h6></a>
                            <div className="halfUnderlineDiv"></div>
                        </div>
                        <div className="col-md-5 featureImageLeftSectionImage">
                            <img src={LandingCreateGroups} alt="Landing Page Feature" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="fifthSectionOfLandingPageStartHere">
                <div className="container-fluid fourthSectionOfLandingPageFirstInnerDiv">
                    <div className="row justify-content-around">
                    <div className="col-md-5 featureImageLeftSectionImage">
                            <img src={LandingIncognitoGroups} alt="Landing Page Feature" className='img-fluid' />
                        </div>
                        <div className="col-md-5 featureSectionRightHeadingAndPara">
                        <h4>Create incognito groups</h4>
                        <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Privacy taken a notch higher</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Add participants as an admin and give them aliases</td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid' /></td>
                                        <td className='groupsParagraph'>Each participant is anonymous to the other</td>
                                    </tr>
                                </tbody>
                            </table>
                            <a href="/" className="href"><button>Demo</button></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandingPage;