import React from 'react';
import "../CSS_File/PrivacyPage.css";
// import { MdCallMade } from "react-icons/md";
import { Helmet } from 'react-helmet';
import PrivacyOne from "../../Images/Privacy_Page/Privacy_1.webp";
import PrivacyTwo from "../../Images/Privacy_Page/Privacy_2.webp";
import PrivacyThree from "../../Images/Privacy_Page/Privacy_3.webp";

const PrivacyPage = () => {
    return (
        <>
            <Helmet>
                <title>Privacy | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/privacy" />
            </Helmet>
            <section className="privacyPageFirstSectionStartHere">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-md-5 privacyPageImageDiv">
                            <img src={PrivacyOne} alt="Privacy One" className='img-fluid' />
                        </div>
                        <div className="col-md-5 privacyPageParagraphDiv">
                            <h5>End-to-end encryption</h5>
                            <p><span>Messages</span> can be read by the recipient and the sender only. Our encryption tools enhance your privacy.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacyPageSecondSectionStartHere">
                <div className="container">
                    <div className="row justify-content-around privacyPageSecondSectionSecondInnerDiv">
                        <div className="col-md-5 privacyPageParagraphDiv">
                            <h5>No data sharing</h5>
                            <p><span>No</span> data stored leads to no data sharing because we value your privacy.</p>
                        </div>
                        <div className="col-md-5 privacyPageImageDiv">
                            <img src={PrivacyTwo} alt="Privacy Two" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacyPageThirdSectionStartHere">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-md-5 privacyPageImageDiv">
                            <img src={PrivacyThree} alt="Privacy Three" className='img-fluid' />
                        </div>
                        <div className="col-md-5 privacyPageParagraphDiv">
                            <h5>Incognito Groups</h5>
                            <p><span>Only</span> the group admin knows everyone. The rest of the participants do not get to know the identity of other participants the left hand does not get to know the right hand.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacyPageLastSectionStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-4 cardHeadingAndButtonLeftDiv">
                            <h4>How do we maintain privacy?</h4>
                            <p><a href="/" className="href">Learn More</a></p>
                            {/* <p><a href="/" className="href">Learn More <span><i><MdCallMade /></i></span></a></p> */}
                        </div>
                        <div className="col-md-4 cardHeadingAndButtonMiddleDiv">
                            <h4>Features of private group</h4>
                            <p><a href="/" className="href">Learn More</a></p>
                        </div>
                        <div className="col-md-4 cardHeadingAndButtonRightDiv">
                            <h4>How to add new participant in private group?</h4>
                            <p><a href="/" className="href">Learn More</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPage;