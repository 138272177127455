import React from 'react';
import { Helmet } from 'react-helmet';
import FeaturesFirstParaPointOne from "../../Images/Features/feature_image_1.webp";
import FeaturesFirstParaPointTwo from "../../Images/Features/feature_image_2.webp";
import FeaturesPageClickIcon from "../../Images/Features/material-symbols_arrow-outward.png";
import LandingClickIcon from "../../Images/Landing_Page/charm_circle_tick.png";
import "../CSS_File/FeaturesPage.css";

const FeaturesPage = () => {
    return (
        <>
            <Helmet>
                <title>Features | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/features" />
            </Helmet>
            <section className="featuresFirstSectionBannerPartStartHere">
                <div className="container featureFirstSectionsFirstInnerDiv">
                    <div className="row justify-content-around">
                        <div className="col-md-5 featuresPageImage">
                            <img src={FeaturesFirstParaPointOne} alt="Landing Page Feature" className='img-fluid' />
                        </div>
                        <div className="col-md-5 featuresPageHeadingAndIcon">
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconUpperImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>End-to-end encrypted</h6>
                                            <p><span>We</span> ensure that your personal messages can only be accessed by you and you only</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconUpperImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>Share every moment with your loved ones</h6>
                                            <p><span>Share</span> photos and videos seamlessly with your loved ones</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconUpperImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>Location sharing</h6>
                                            <p><span>Share</span> your current or real time location</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <section className="featuresPageSecondSectionStartHere">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-md-5 featuresPageHeadingAndIcon">
                            <table className='table table-borderless'>
                                <tbody>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconBelowImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>Incognito groups</h6>
                                            <p><span>Maintain</span> confidentiality of users by creating incognito groups</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconBelowImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>Stay connected with your group</h6>
                                            <p><span>Stay</span> connected and improve your networking through various groups</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><img src={LandingClickIcon} alt="Click Icon" className='img-fluid clickIconBelowImage' /></td>
                                        <td className='groupsParagraph'>
                                            <h6>Advertisement free</h6>
                                            <p><span>No</span> ads so that you can connect easily with your loved ones</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-5 featuresPageImage">
                            <img src={FeaturesFirstParaPointTwo} alt="Landing Page Feature" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='featuresPageLastSection'>
                <div className="container">
                    <div className="row-justify-content-center">
                        <div className="col-md-12 featuresLastSectionHeading">
                            <h2>Need more help?</h2>
                            <a href="/" className="href"><p><span>Get started</span> <span><img src={FeaturesPageClickIcon} alt="Features Page Click Icon" /></span></p></a>
                            <a href="/" className="href"><p><span>How to report a person?</span> <span><img src={FeaturesPageClickIcon} alt="Features Page Click Icon" /></span></p></a>
                            <a href="/" className="href"><p><span>How to create private group?</span> <span><img src={FeaturesPageClickIcon} alt="Features Page Click Icon" /></span></p></a>
                            <a href="/" className="href"><p><span>Get started</span> <span><img src={FeaturesPageClickIcon} alt="Features Page Click Icon" /></span></p></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeaturesPage;