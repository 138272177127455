import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import "../CSS_File/PrivacyPolicyPage.css";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Nexus Chatapp</title>
        {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
        <link rel="canonical" href="https://www.nexuschatapp.com/privacyPolicy" />
      </Helmet>
      <section className="nexusPrivacyPolicyPageStartHere">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 privacyPolicyContent">
              <h1>Nexus Privacy Policy</h1>
              <p>Our Privacy Policy ("Privacy Policy") helps explain our data practices, including the information we process to provide our Services.</p>
              <p>For example, our Privacy Policy talks about what information we collect and how this affects you. It also explains the steps we take to protect your privacy, like building our Services so delivered messages aren't stored by us and giving you control over who you communicate with on our Services.</p>
              <p>We are one of the Holistic Learning. You can learn more further below in this Privacy Policy about the ways in which we share information across this family of companies.</p>
              <p>This Privacy Policy applies to all of our Services unless specified otherwise.</p>
              <p>Please also read Nexus's Terms of Service ("Terms"), which describe the terms under which you use and we provide our Services.</p>
              <h3>Nexus Legal Info</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.</p>
              <h3>Key Updates</h3>
              <p>Respect for your privacy is coded into our DNA. Since we started Nexus, we've built our services with a set of strong privacy principles in mind. In our updated Terms of Service and Privacy Policy you'll find:</p>
              <ul>
                <li>Additional Information On How We Handle Your Data. Our updated Terms and Privacy Policy provide more information on how we process your data, and our commitment to privacy. For example, we've added more information about more recent product features and functionalities, how we process your data for safety, security, and integrity, and added more direct links to user settings, Help Center articles and how you can manage your information.</li>
                <li>Better Communication With Businesses. Many businesses rely on Nexus to communicate with their customers and clients. We work with businesses that use Meta or third parties to help store and better manage their communications with you on Nexus.</li>
                <li>Making It Easier To Connect. As part of the Holistic Learning, Nexus partners with Meta to offer experiences and integrations across Meta's family of apps and products.</li>
              </ul>
              <h3>Information We Collect</h3>
              <p>Nexus must receive or collect some information to operate, provide, improve, understand, customize, support, and market our Services, including when you install, access, or use our Services.</p>
              <p>Contact upload is a voluntary feature that enables us to identify Nexus users among your device's address book contacts. This feature also helps us maintain an updated Nexus contact list by automatically including any new contacts who join Nexus in the future. We value your privacy, and we want to emphasize that we do not share your contact information with Meta Platforms Inc. or any other Meta companies for their purposes, even when they assist us with our services.</p>
              <p>When you enable Nexus access to your phone's address book via the contact upload feature, Nexus verifies and updates the phone numbers in your contacts on a regular basis. Nexus conducts routine checks, which consider factors like your app usage frequency and the activity of your contacts. These checks do not involve accessing additional information from your address book, such as names, email addresses, or other contact details.</p>
              <p>To protect the privacy of contacts who haven't joined Nexus yet, we use a method that keeps their phone numbers anonymous. We do this by creating a unique code for each phone number and then removing the actual number. These codes are stored on Nexus's servers and are associated with the Nexus users who shared these phone numbers before they were transformed into codes. This way, we can help you connect with these contacts more quickly once they join Nexus.</p>
              <p>Additionally, we create a secure, coded representation of the phone numbers stored in your device's contact list. This representation helps us identify and prevent any misuse of contact uploads. We may analyze these codes to check for any unusual changes in your address book, but we don't track or compare individual phone numbers.</p>
              <p>You have control over the contact upload feature through your device's settings. If you decide not to use it, you can still chat with other Nexus users, but some features may not be available to you.</p>
              <p>The types of information we receive and collect depend on how you use our Services. We require certain information to deliver our Services and without this we will not be able to provide our Services to you. For example, you must provide your mobile phone number to create an account to use our Services.</p>
              <p>Our Services have optional features which, if used by you, require us to collect additional information to provide such features. You will be notified of such collection, as appropriate. If you choose not to provide the information needed to use a feature, you will be unable to use the feature. For example, you cannot share your location with your contacts if you do not permit us to collect your location data from your device. Permissions can be managed through your Settings menu on both Android and iOS devices.</p>
              <p>Information You Provide</p>
              <ul>
                <li>Your Account Information. You must provide your mobile phone number and basic information (including a profile name of your choice) to create a Nexus account. If you don't provide us with this information, you will not be able to create an account to use our Services. You can add other information to your account, such as a profile picture and "about" information.</li>
                <li>Your Messages. We do not retain your messages in the ordinary course of providing our Services to you. Instead, your messages are stored on your device and not typically stored on our servers. Once your messages are delivered, they are deleted from our servers. The following scenarios describe circumstances where we may store your messages in the course of delivering them:
                  <ul>
                    <li>Undelivered Messages. If a message cannot be delivered immediately (for example, if the recipient is offline), we keep it in encrypted form on our servers for up to 30 days as we try to deliver it. If a message is still undelivered after 30 days, we delete it.</li>
                    <li>Media Forwarding. When a user forwards media within a message, we store that media temporarily in encrypted form on our servers to aid in more efficient delivery of additional forwards.</li>
                    <li>We offer end-to-end encryption for our Services. End-to-end encryption means that your messages are encrypted to protect against us and third parties from reading them. Learn more about end-to-end encryption and how businesses communicate with you on Nexus.</li>
                  </ul>
                </li>
                <li>Your Connections. You can use the contact upload feature and provide us, if permitted by applicable laws, with the phone numbers in your address book on a regular basis, including those of users of our Services and your other contacts. If any of your contacts aren't yet using our Services, we'll manage this information for you in a way that ensures those contacts cannot be identified by us. Learn more about our contact upload feature here. You can create, join, or get added to groups and broadcast lists, and such groups and lists get associated with your account information. You give your groups a name. You can provide a group profile picture or description.</li>
                <li>Status Information. You may provide us your status if you choose to include one on your account. Learn how to use status on Android, iPhone, or KaiOS.</li>
                <li>Transactions And Payments Data. If you use our payments services, or use our Services meant for purchases or other financial transactions, we process additional information about you, including payment account and transaction information. Payment account and transaction information includes information needed to complete the transaction (for example, information about your payment method, shipping details and transaction amount). If you use our payments services available in your country or territory, our privacy practices are described in the applicable payments privacy policy.</li>
                <li>Customer Support And Other Communications. When you contact us for customer support or otherwise communicate with us, you may provide us with information related to your use of our Services, including copies of your messages, any other information you deem helpful, and how to contact you (e.g., an email address). For example, you may send us an email with information relating to app performance or other issues.</li>
              </ul>
              <p>Automatically Collected Information</p>
              <ul>
                <li>Usage And Log Information. We collect information about your activity on our Services, like service-related, diagnostic, and performance information. This includes information about your activity (including how you use our Services, your Services settings, how you interact with others using our Services (including when you interact with a business), and the time, frequency, and duration of your activities and interactions), log files, and diagnostic, crash, website, and performance logs and reports. This also includes information about when you registered to use our Services; the features you use like our messaging, calling, Status, groups (including group name, group picture, group description), payments or business features; profile photo, "about" information; whether you are online, when you last used our Services (your "last seen"); and when you last updated your "about" information.</li>
                <li>Device And Connection Information. We collect device and connection-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, battery level, signal strength, app version, browser information, mobile network, connection information (including phone number, mobile operator or ISP), language and time zone, IP address, device operations information, and identifiers (including identifiers unique to Holistic Learning Products associated with the same device or account).</li>
                <li>Location Information. We collect and use precise location information from your device with your permission when you choose to use location-related features, like when you decide to share your location with your contacts or view locations nearby or locations others have shared with you. There are certain settings relating to location-related information which you can find in your device settings or the in-app settings, such as location sharing. Even if you do not use our location-related features, we use IP addresses and other information like phone number area codes to estimate your general location (e.g., city and country). We also use your location information for diagnostics and troubleshooting purposes.</li>
                <li>Cookies. We use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize them. For example, we use cookies to provide our Services for web and desktop and other web-based services. We may also use cookies to understand which of our Help Center articles are most popular and to show you relevant content related to our Services. Additionally, we may use cookies to remember your choices, like your language preferences, to provide a safer experience, and otherwise to customize our Services for you. Learn more about how we use cookies to provide you our Services.</li>
              </ul>
              <h3>Third-Party Information</h3>
              <ul>
                <li>Information Others Provide About You. We receive information about you from other users. For example, when other users you know use our Services, they may provide your phone number, name, and other information (like information from their mobile address book) just as you may provide theirs. They may also send you messages, send messages to groups to which you belong, or call you. We require each of these users to have lawful rights to collect, use, and share your information before providing any information to us. <br />You should keep in mind that in general any user can capture screenshots of your chats or messages or make recordings of your calls with them and send them to Nexus or anyone else, or post them on another platform.</li>
                <li>User Reports. Just as you can report other users, other users or third parties may also choose to report to us your interactions and your messages with them or others on our Services; for example, to report possible violations of our Terms or policies. When a report is made, we collect information on both the reporting user and reported user. To find out more about what happens when a user report is made, please see Advanced Safety and Security Features.</li>
                <li>Businesses On Nexus. Businesses you interact with using our Services may provide us with information about their interactions with you. We require each of these businesses to act in accordance with applicable law when providing any information to us. <br />When you message with a business on Nexus, keep in mind that the content you share may be visible to several people in that business. In addition, some businesses might be working with third-party service providers (which may include Meta) to help manage their communications with their customers. For example, a business may give such third-party service provider access to its communications to send, store, read, manage, or otherwise process them for the business. To understand how a business processes your information, including how it might share your information with third parties or Meta, you should review that business' privacy policy or contact the business directly.</li>
                <li>Third-Party Service Providers. We work with third-party service providers and other Holistic Learning to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with them to distribute our apps; provide our technical and physical infrastructure, delivery, and other systems; provide engineering support, cybersecurity support, and operational support; supply location, map, and places information; process payments; help us understand how people use our Services; market our Services; help you connect with businesses using our Services; conduct surveys and research for us; ensure safety, security, and integrity; and help with customer service. These companies may provide us with information about you in certain circumstances; for example, app stores may provide us with reports to help us diagnose and fix service issues. <br />The "How We Work With Other Holistic Learning" section below provides more information about how Nexus collects and shares information with other Holistic Learning.</li>
                <li>Third-Party Services. We allow you to use our Services in connection with third-party services and Holistic Learning Products. If you use our Services with such third-party services or Holistic Learning Products, we may receive information about you from them; for example, if you use the Nexus share button on a news service to share a news article with your Nexus contacts, groups, or broadcast lists on our Services, or if you choose to access our Services through a mobile carrier's or device provider's promotion of our Services. Please note that when you use third-party services or Holistic Learning Products, their own terms and privacy policies will govern your use of those services and products.</li>
              </ul>
              <h3>How We Use Information</h3>
              <p>We use the information we have (subject to choices you make and applicable law) to operate, provide, improve, understand, customize, support, and market our Services. Here's how:</p>
              <ul>
                <li>Our Services. We use the information we have to operate and provide our Services, including providing customer support; completing purchases or transactions; improving, fixing, and customizing our Services; and connecting our Services with Holistic Learning Products that you may use. We also use the information we have to understand how people use our Services; evaluate and improve our Services; research, develop, and test new services and features; and conduct troubleshooting activities. We also use your information to respond to you when you contact us.</li>
                <li>Safety, Security, And Integrity. Safety, security, and integrity are an integral part of our Services. We use the information we have to verify accounts and activity; combat harmful conduct; protect users against bad experiences and spam; and promote safety, security, and integrity on and off our Services, such as by investigating suspicious activity or violations of our Terms and policies, and to ensure our Services are being used legally. Please see the Law, Our Rights and Protection section below for more information.</li>
                <li>Communications About Our Services And The Holistic Learning. We use the information we have to communicate with you about our Services and let you know about our terms, policies, and other important updates. We may provide you with marketing for our Services and those of the Holistic Learning.</li>
                <li>No Third-Party Banner Ads. We still do not allow third-party banner ads on our Services. We have no intention to introduce them, but if we ever do, we will update this Privacy Policy.</li>
                <li>Business Interactions. We enable you and third parties, like businesses, to communicate and interact with each other using our Services, such as Catalogs for businesses on Nexus through which you can browse products and services and place orders. Businesses may send you transaction, appointment, and shipping notifications; product and service updates; and marketing. For example, you may receive flight status information for upcoming travel, a receipt for something you purchased, or a notification when a delivery will be made. Messages you receive from a business could include an offer for something that might interest you. We do not want you to have a spammy experience; as with all of your messages, you can manage these communications, and we will honor the choices you make.</li>
              </ul>
              <h3>Information You And We Share</h3>
              <p>You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.</p>
              <ul>
                <li>Send Your Information To Those With Whom You Choose To Communicate. You share your information (including messages) as you use and communicate through our Services.</li>
                <li>Information Associated With Your Account. Your phone number, profile name and photo, "about" information, last seen information, and message receipts are available to anyone who uses our Services, although you can configure your Services settings to manage certain information available to other users, including businesses, with whom you communicate.</li>
                <li>Your Contacts And Others. Users, including businesses, with whom you communicate can store or reshare your information (including your phone number or messages) with others on and off our Services. You can use your Services settings and the “block” feature in our Services to manage who you communicate with on our Services and certain information you share.</li>
                <li>Businesses On Nexus. We offer specific services to businesses such as providing them with metrics regarding their use of our Services.</li>
                <li>Third-Party Service Providers. We work with third-party service providers and other Holistic Learning to help us operate, provide, improve, understand, customize, support, and market our Services. We work with these companies to support our Services, such as to provide technical infrastructure, delivery and other systems; market our Services; conduct surveys and research for us; protect the safety, security, and integrity of users and others; and assist with customer service. When we share information with third-party service providers and other Holistic Learning in this capacity, we require them to use your information on our behalf in accordance with our instructions and terms.</li>
                <li>Third-Party Services. When you or others use third-party services or other Holistic Learning Products that are integrated with our Services, those third-party services may receive information about what you or others share with them. For example, if you use a data backup service integrated with our Services (like iCloud or Google Drive), they will receive information you share with them, such as your Nexus messages. If you interact with a third-party service or another Holistic Learning Product linked through our Services, such as when you use the in-app player to play content from a third-party platform, information about you, like your IP address and the fact that you are a Nexus user, may be provided to such third party or Holistic Learning Product. Please note that when you use third-party services or other Holistic Learning Products, their own terms and privacy policies will govern your use of those services and products.</li>
              </ul>
              <h3>How We Work With Other Holistic Learning</h3>
              <p>As part of the Holistic Learning, Nexus receives information from, and shares information (see here) with, the other Holistic Learning. We may use the information we receive from them, and they may use the information we share with them, to help operate, provide, improve, understand, customize, support, and market our Services and their offerings, including the Holistic Learning Products. This includes:</p>
              <ul>
                <li>helping improve infrastructure and delivery systems;</li>
                <li>understanding how our Services or theirs are used;</li>
                <li>promoting safety, security, and integrity across the Holistic Learning Products, e.g., securing systems and fighting spam, threats, abuse, or infringement activities;</li>
                <li>improving their services and your experiences using them, such as making suggestions for you (for example, of friends or group connections, or of interesting content), personalizing features and content, helping you complete purchases and transactions, and showing relevant offers and ads across the Holistic Learning Products; and</li>
                <li>providing integrations which enable you to connect your Nexus experiences with other Holistic Learning Products. For example, allowing you to connect your Facebook Pay account to pay for things on Nexus or enabling you to chat with your friends on other Holistic Learning Products, such as Portal, by connecting your Nexus account.</li>
              </ul>
              <p>Learn more about the other Holistic Learning and their privacy practices by reviewing their privacy policies.</p>
              <h3>Assignment, Change Of Control, And Transfer</h3>
              <p>In the event that we are involved in a merger, acquisition, restructuring, bankruptcy, or sale of all or some of our assets, we will share your information with the successor entities or new owners in connection with the transaction in accordance with applicable data protection laws.</p>
              <h3>Managing And Retaining Your Information</h3>
              <p>You can access or port your information using our in-app Request Account Info feature (available under Settings &gt; Account). For iPhone users, you can learn how to access, manage, and delete your information through our iPhone Help Center articles. For Android users, you can learn how to access, manage, and delete your information through our Android Help Center articles.</p>
              <p>We store information for as long as necessary for the purposes identified in this Privacy Policy, including to provide our Services or for other legitimate purposes, such as complying with legal obligations, enforcing and preventing violations of our Terms, or protecting or defending our rights, property, and users. The storage periods are determined on a case-by-case basis that depends on factors like the nature of the information, why it is collected and processed, relevant legal or operational retention needs, and legal obligations.</p>
              <p>If you would like to further manage, change, limit, or delete your information, you can do that through the following tools:</p>
              <ul>
                <li>Services Settings. You can change your Services settings to manage certain information available to other users. You can manage your contacts, groups, and broadcast lists, or use our “block” feature to manage the users with whom you communicate.</li>
                <li>Changing Your Mobile Phone Number, Profile Name And Picture, And “About” Information. If you change your mobile phone number, you must update it using our in-app change number feature and transfer your account to your new mobile phone number. You can also change your profile name, profile picture, and "about" information anytime.</li>
                <li>Deleting Your Nexus Account. You can delete your Nexus account at any time (including if you want to revoke your consent to our use of your information pursuant to applicable law) using our in-app delete my account feature. When you delete your Nexus account, your undelivered messages are deleted from our servers as well as any of your other information we no longer need to operate and provide our Services. Deleting your account will, for example, delete your account info and profile photo, delete you from all Nexus groups, and delete your Nexus message history. Be mindful that if you only delete Nexus from your device without using our in-app delete my account feature, your information will be stored with us for a longer period. Please remember that when you delete your account, it does not affect your information related to the groups you created or the information other users have relating to you, such as their copy of the messages you sent them.</li>
              </ul>
              <p>You can learn more here about our data deletion and retention practices and about how to delete your account.</p>
              <h3>Law, Our Rights, And Protection</h3>
              <p>We access, preserve, and share your information described in the "Information We Collect" section of this Privacy Policy above if we have a good-faith belief that it is necessary to: (a) respond pursuant to applicable law or regulations, legal process, or government requests; (b) enforce our Terms and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, or address fraud and other illegal activity or security, and technical issues; or (d) protect the rights, property, and safety of our users, Nexus, the other Holistic Learning, or others, including to prevent death or imminent bodily harm.</p>
              <h3>Our Global Operations</h3>
              <p>Nexus shares information globally, both internally within the Holistic Learning and externally with our partners and service providers, and with those with whom you communicate around the world, in accordance with this Privacy Policy. Your information may, for example, be transferred or transmitted to, or stored and processed in, the United States; countries or territories where the Holistic Learning' affiliates and partners, or our service providers are located; or any other country or territory globally where our Services are provided outside of where you live for the purposes as described in this Privacy Policy.</p>
              <p>Nexus uses Meta's global infrastructure and data centers, including in the United States. These transfers are necessary to provide the global Services set forth in our Terms. Please keep in mind that the countries or territories to which your information is transferred may have different privacy laws and protections than what you have in your home country or territory.</p>
              <h3>Updates To Our Policy</h3>
              <p>We may amend or update our Privacy Policy. We will provide you notice of amendments to this Privacy Policy, as appropriate, and update the “Last modified” date at the top of this Privacy Policy. Please review our Privacy Policy from time to time.</p>
              <h3>Contact Us</h3>
              <p>If you have questions or issues about our Privacy Policy, please <Link to={"/contactUs"}><span>contact us</span></Link>.</p>
              <p>#40, 1st Floor,</p>
              <p>10th Cross Road,</p>
              <p>Wilson Garden, Bengaluru,</p>
              <p>Karnataka - 560 027</p>
              <p>Email - <span>nexuschatapp2023@gmail.com</span></p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicyPage;