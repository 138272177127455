import { NavLink } from 'react-router-dom';

export const ourBlogs = [
    {
        id : 1,
        blogImage : require('../../Images/Blogs_Page/blog_1.png'),
        title : "Use Case",
        description : "Apart from your daily needs of staying connected with your family, friends and colleagues, the app has a subtle use in business also. We are in the nascent stage of app development and have thus restricted the functionality of the app to bare minimum - so while voice and video calls are not part of the app currently, users can still chat with their connections and share photos, videos and documents.",
        newPage : <span><NavLink to={"/blogs/use-case"} className="href">Read More</NavLink></span>,
        altTag: "Use Case"
    },
    {
        id : 2,
        blogImage : require('../../Images/Blogs_Page/blog_2.png'),
        title : "Blog Page",
        description : "The Nexus software program allows users to communicate with other users in real-time. These types of applications have become increasingly popular in recent years, particularly with the proliferation of smartphones and the rise of social media. Our goal is to act as a bridge between customer and business in which a third party is involved. We recognized the need for more control over the end customers and to ensure the private chat with the customers.",
        newPage : <span><NavLink to={"/blogs/blog-page"} className="href">Read More</NavLink></span>,
        altTag: "Blog Page"
    },
    {
        id : 3,
        blogImage : require('../../Images/Blogs_Page/blog_3.png'),
        title : "How To Find Reliable Sources For Research Paper",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-one"} className="href">Read More</NavLink></span>,
        altTag: "How To Find Reliable Sources For Research Paper"
    },
    {
        id : 4,
        blogImage : require('../../Images/Blogs_Page/blog_4.png'),
        title : "How To Write A Dissertation Literature Review?",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-two"} className="href">Read More</NavLink></span>,
        altTag: "how to write dissertation literature review"
    },
    {
        id : 5,
        blogImage : require('../../Images/Blogs_Page/blog_1.png'),
        title : "Comparison Between Dissertation And Thesis",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-one"} className="href">Read More</NavLink></span>,
        altTag: "Comparison Between Dissertation And Thesis"
    },
    {
        id : 6,
        blogImage : require('../../Images/Blogs_Page/blog_2.png'),
        title : "Custom Dissertation Writing Service",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-two"} className="href">Read More</NavLink></span>,
        altTag: "Custom Dissertation Writing Service"
    },
    {
        id : 7,
        blogImage : require('../../Images/Blogs_Page/blog_3.png'),
        title : "Reasons to Hire a Dissertation Writing Service",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-one"} className="href">Read More</NavLink></span>,
        altTag: "Reasons to Hire a Dissertation Writing Service"
    },
    {
        id : 8,
        blogImage : require('../../Images/Blogs_Page/blog_4.png'),
        title : "How To Write a Dissertation Bibliography",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-two"} className="href">Read More</NavLink></span>,
        altTag: "How To Write a Dissertation Bibliography"
    },
    {
        id : 9,
        blogImage : require('../../Images/Blogs_Page/blog_1.png'),
        title : "How to Write a Conclusion for a Dissertation",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-one"} className="href">Read More</NavLink></span>,
        altTag: "How to Write a Conclusion for a Dissertation"
    },
    {
        id : 10,
        blogImage : require('../../Images/Blogs_Page/blog_2.png'),
        title : "Writing Contents Table for Dissertation",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
        newPage : <span><NavLink to={"/blogs/blog-number-two"} className="href">Read More</NavLink></span>,
        altTag: "Writing Contents Table for Dissertation"
    }
];