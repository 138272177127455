import React from 'react'
import SecondBlog from "../../Images/Blogs_Page/blog_2.png"
import "../CSS/ForAllIndividualBlog.css"

const BlogNumberTwo = () => {
  return (
    <>
    <section className="blogIndividualPageSectionStartHere">
        <div className="container blogIndividualPageInnerDiv">
            <div className="row justify-content-center">
                <div className="col-md-12 blogIndividualImageHeadingAndParaDiv">
                    <img src={SecondBlog} alt="Second Blog" className='img-fluid' />
                    <article className='blogsNumberOneArticleSection'>
                    <h1>Create anonymous group #2</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                    <p>Dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                    <p>Aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                    <p>Aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                    </article>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BlogNumberTwo;