import React from 'react'
import { useState, useEffect } from "react";
import { ourBlogs } from '../../Data/BlogsCard/BlogsCardData';
import BlogsPage from './BlogsPage';

const BlogsCardMainPage = () => {
    const [data, setData] = useState(ourBlogs);
    //getting posts
    useEffect(() => {
      const paginationFunc = () => {
        setData(data);
        // console.log(data);
      };
      paginationFunc();
    }, []);
  return (
    <>
    {data && data.length > 0 ? <BlogsPage data={data} /> : <p>Loading...</p>}
    </>
  )
}

export default BlogsCardMainPage;