import React from 'react'
import { Helmet } from 'react-helmet'
import FirstBlog from "../../Images/Blogs_Page/blog_1.png"
import "../CSS/ForAllIndividualBlog.css"

const UseCaseBlog = () => {
    return (
        <>
            <Helmet>
                <title>Use Case | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/blogs/use-case" />
            </Helmet>
            <section className="blogIndividualPageSectionStartHere">
                <div className="container blogIndividualPageInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blogIndividualImageHeadingAndParaDiv">
                            <img src={FirstBlog} alt="First Blog" className='img-fluid' />
                            <article className='blogsNumberOneArticleSection'>
                                <h1>Use Case</h1>
                                <p>Apart from your daily needs of staying connected with your family, friends and colleagues, the app has a subtle use in business also. We are in the nascent stage of app development and have thus restricted the functionality of the app to bare minimum - so while voice and video calls are not part of the app currently, users can still chat with their connections and share photos, videos and documents. As and when the app gets some traction, we are committed to provide higher end functionalities also. As mentioned earlier, there are few business use cases of our app. We have listed a few below. If you use the app for more cases apart from the ones listed here, please do share the same with us. Your stories and use cases will reaffirm our belief and boost our app development process.</p>
                                <p>Apart from the normal group functionality, we have developed ‘an incognito group’ feature. You can call it ‘a private group’, ‘a secure group’. We choose to call it ‘incognito groups’. The basic premise of an incognito group is that the participants do not get to know each other and there is no way they can talk to each other outside the group. The participants are added to the group by an admin - who obviously knows all the participants and all the participants individually know the admin. The fun part is that the participants never get to know each other. Below is the step as to how the group can be created by an admin:</p>
                                <ul>
                                    <li>Go to group creation option</li>
                                    <li>Add the participants</li>
                                    <li>Name the group, add a description</li>
                                    <li>There is a toggle button which asks you to choose between an Incognito group and a normal group.</li>
                                    <li>Besides the name of each participant, there is an edit icon. This option allows you to give aliases to your contact. Only the admin can give aliases.</li>
                                    <li>Create the group</li>
                                </ul>
                                <p>The incognito group feature can be used by all those who act as a bridge between two sides with the intention of not sharing the details of both sides with each other.</p>
                                <h2>Use Case 1:</h2>
                                <p>An education service provider wants to connect a student with a tutor. However, the service provider is wary of sharing the personal information of the student with the tutor and vice versa. The service provider can thus create an incognito group and be the admin, add the student and tutor in the group and instead of displaying their contact number or real names, can give aliases like “student’ and ‘tutor’. Our app thus ensures an increased privacy and security to the education service provider.</p>
                                <h2>Use Case 2:</h2>
                                <p>A trader (someone who acts as a bridge between a supplier and a consumer or a retailer) could benefit immensely from Nexus. A traditional trading business works in a peculiar way - the customer provides the list of specification for a product to the trader, the trader checks the same specifications with the suppliers and then reverts back to the customer, the customer puts forward further queries, the trader again talks to the suppliers and get the queries addressed and passes the same to the customer. This to and fro communication takes place on individual level where the trader acts as a postman and keeps relaying messages from one point to another. The trader never gets the customer and the supplier together on the same platform fearing that either of them may skip him and do the deal directly. However, with Nexus, the problem has been addressed. The trader can simply create an incognito group and add the customer and the supplier in the same group. The trader can just give any alias to both customer and supplier. The customer and the supplier can talk to each other directly without revealing their identities and the trader gets to monitor and control the chat. The trader can delete unwanted messages from either party and thus have full control over the conversation. This cuts his communication time drastically, the customer’s queries are addressed faster and more reliably.</p>
                                <h2>Use Case 3:</h2>
                                <p>In the Online Class Help industry, the colleges have taken security a notch higher and thus a tutor finds it difficult to access the class. With 2FA being used by most colleges, the tutor can no longer access the class without assistance from the student. So after 2FA, this is how the login process works: the tutor accesses the class and requests the code, after requesting the code ask the client for the same, the client contacts the student for the code, the student shares the code to the client who in turn passes it over to the tutor. Now, if we add one more party in the picture who monitors the tutor, the chain of communication increases from three to four. Again the client cannot get the tutor and student together as this is a price sensitive industry and even a discount of $50 is enough for the student to ditch the current service provider. Hence security of data and private information is very vital. This is where Nexus plays a vital role. The incognito group feature as explained earlier not only provides the required security and confidentiality to the client but also increases the speed of communication and code sharing. The 2FA code is generally valid for only 15 seconds. Hence the need for faster communication and the need to reduce the time in sharing the code.</p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UseCaseBlog;