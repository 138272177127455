import React from 'react'
import { Helmet } from 'react-helmet'
import "../CSS/DownloadAndInstallation.css"

const DownloadAndInstallation = () => {
    return (
        <>
            <Helmet>
                <title>Download & Inatallation | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/help/downloadAndInstallation" />
            </Helmet>
            <section className="downloadAndInstallationPageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 downloadAndInstallationPageContent">
                            <h1>Download &amp; Installation</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
                            <h2>Login</h2>
                            <p>Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                            <h2><span>1.</span> Open Nexus</h2>
                            <p>Open Nexus app from your app drawer</p>
                            <h2><span>2.</span> Enter phone number</h2>
                            <p>Enter phone number in the text field to register. </p>
                            <h2><span>3.</span> Enter OTP</h2>
                            <p>Once the OTP is entered, your number will be registered in the app.</p>
                            <h2>Logout</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DownloadAndInstallation