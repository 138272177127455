import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, Outlet } from 'react-router-dom';
import SearchIcon from "../../Images/Help_Page/Vector.png";
import "../CSS_File/HelpPage.css";

const HelpPage = () => {
  function download() {
    let articlesHeading = document.getElementById("articlesHeading");
    // if (window.location.href != "http://localhost:3001/helpPage") {
    //   articlesHeading.classList.add("articlesHeading");
    //   articlesHeading.classList.remove("articleHeading");
    //   console.log("add")
    //   } else {
    //    articlesHeading.classList.add("articleHeading");
    //    articlesHeading.classList.remove("articlesHeading");
    //    console.log("remove")
    //  }
    if (articlesHeading.classList.contains("articlesHeading")) {
      articlesHeading.classList.remove("articlesHeading");
    } else {
      articlesHeading.classList.add("articlesHeading")
    }
    articlesHeading.classList.toggle("articlesHeading");
  }
  return (
    <>
      <Helmet>
        <title>Help | Nexus Chatapp</title>
        {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
        <link rel="canonical" href="https://www.nexuschatapp.com/help" />
      </Helmet>
      <section className="helpPageFirstSectonStartHere">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-3 helpPageFAQSection">
              <div className="accordion accordion-flush helpPageAccordion" id="accordionFlushExample">
                <div className="accordion-item accordionItem">
                  <h2 className="accordion-header accordionHeader" id="flush-headingOne">
                    <button className="accordion-button collapsed accordionButton" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      Get Started
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body accordionBody">
                      <p onClick={download}><NavLink to={"downloadAndInstallation"} className="href">Download &amp; Installation</NavLink></p>
                      <p><a href="/" className="href">Login &amp; Authentication</a></p>
                      <p><a href="/" className="href">Contacts</a></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordionItem">
                  <h2 className="accordion-header accordionHeader" id="flush-headingTwo">
                    <button className="accordion-button collapsed accordionButton" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      Chats
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body accordionBody">
                      <p><a href="/" className="href">Individual &amp; group chat</a></p>
                      <p><a href="/" className="href">Anonymous group creation</a></p>
                      <p><a href="/" className="href">Media</a></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordionItem">
                  <h2 className="accordion-header accordionHeader" id="flush-headingThree">
                    <button className="accordion-button collapsed accordionButton" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      Privacy &amp; Security
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body accordionBody">
                      <p><a href="/" className="href">Privacy</a></p>
                      <p><a href="/" className="href">Security</a></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordionItem">
                  <h2 className="accordion-header accordionHeader" id="flush-headingFour">
                    <button className="accordion-button collapsed accordionButton" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      Accounts
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body accordionBody">
                      <p><a href="/" className="href">Account ban</a></p>
                      <p><a href="/" className="href">Report and block account</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 helpPageArticlesHeading">
              <img src={SearchIcon} alt="Search Icon" />
              <input type="search" className="noSubmit" placeholder='Search articles...' />
              <Outlet />
              <div id='articlesHeading'>
                <h1>Popular articles</h1>
                <h4><a href="/" className="href">How to create anonymous group?</a></h4>
                <h4><a href="/" className="href">How to block and report a person?</a></h4>
                <h4><a href="/" className="href">How to deactivate my account?</a></h4>
                <h4><a href="/" className="href">How to install the app?</a></h4>
                <h4><a href="/" className="href">How to change my number?</a></h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Outlet /> */}
    </>
  )
}

export default HelpPage