import React from 'react';
import "../CSS_File/BlogsPage.css";
// import { BsFillCaretDownFill } from "react-icons/bs";
// import FirstBlog from "../../Images/Blogs_Page/blog_1.png";
// import SecondBlog from "../../Images/Blogs_Page/blog_2.png";
// import { NavLink } from 'react-router-dom';
import Pagination from "@mui/material/Pagination";
import { Helmet } from 'react-helmet';
import { usePagination } from "../../Hooks/BlogCardPagination";

const BlogsPage = ({ data }) => {
    const [
        totalPages,
        startPageIndex,
        endPageIndex,
        currentPageIndex, //eslint-disable-line
        displayPage,
    ] = usePagination(2, data.length);
    return (
        <>
            <Helmet>
                <title>Blogs | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/blogs" />
            </Helmet>
            <section className="blogPagesNumberOfBlogsSection">
                <div className="container blogsFirstInnerDiv">
                    <div className="row justify-content-center">
                        {(() => {
                            const displayPosts = [];
                            for (let i = startPageIndex; i <= endPageIndex; i++) {
                                displayPosts.push(
                                    <div className="col-md-12 blogsContainer" key={data[i].id}>
                                        <img src={data[i].blogImage} alt={data[i].altTag} className='img-fluid' />
                                        <h1>{data[i].title}</h1>
                                        <p className='blogDescription'>{data[i].description}</p>
                                        <p className='readMoreLink'>{data[i].newPage}</p>
                                    </div>
                                );
                            }
                            return displayPosts;
                        })()}
                    </div>
                </div>
            </section>
            <section className="downArrowNextBlogButtonSectionStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 downloadReactIconDivInBlogPage">
                            {/* <p className='downloadReactIconButton'><i><BsFillCaretDownFill /></i></p> */}
                            <Pagination
                                color="primary"
                                count={totalPages}
                                size='large'
                                variant="outlined"
                                sx={{
                                    "button.MuiPaginationItem-rounded.Mui-selected": {
                                        color: "#FFFFFF !important",
                                        border: "1px solid #FFFFFF !important",
                                        bgcolor: "transparent !important"
                                    },

                                    "button.MuiPaginationItem-rounded": {
                                        bgcolor: "#FFFFFF !important",
                                        border: "1px solid #FFFFFF !important",
                                        fontfamily: "'Poppins', sans-serif !important",
                                        fontweight: "500 !important"
                                    }
                                }}
                                shape="rounded"
                                onChange={(event, value) => displayPage(value)}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogsPage;