import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import DownloadOne from "../../Images/Download_Page/Download_1.png";
import DownloadTwo from "../../Images/Download_Page/Download_2.png";
import TickIcon from "../../Images/Download_Page/Download_Tick_Icon.png";
import DownloadThree from "../../Images/Features/appstore_button.png";
import DownloadFour from "../../Images/Features/googleplay_button.png";
import "../CSS_File/DownloadPage.css";

const DownloadPage = () => {
    return (
        <>
            <Helmet>
                <title>Download | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/download" />
            </Helmet>
            <section className="downloadPageFirstSectionStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-4 firstSectionFirstCard firstSectionCardDiv">
                            <p><span className="imgSpan"><img src={TickIcon} alt="Tick Icon" className='img-fluid' /></span><span className="paraSpan ms-3">One-to-one chat</span></p>
                        </div>
                        <div className="col-md-4 firstSectionSecondCard firstSectionCardDiv">
                            <p><span className="imgSpan"><img src={TickIcon} alt="Tick Icon" className='img-fluid' /></span><span className="paraSpan ms-3">Incognito groups</span></p>
                        </div>
                        <div className="col-md-4 firstSectionThirdCard firstSectionCardDiv">
                            <p><span className="imgSpan"><img src={TickIcon} alt="Tick Icon" className='img-fluid' /></span><span className="paraSpan ms-3">Enhanced privacy</span></p>
                        </div>
                        <div className="col-md-12 downloadPageHeading">
                            <h4>Why wait? Create your own Nexus Now!</h4>
                            {/* <h2>Download now</h2> */}
                            <p>By downloading the Nexus app, you agree to the <span><NavLink to={"/privacyPolicy"} className="href">Privacy Policy</NavLink></span> and <span><NavLink to={"/termsOfService"} className="href">Terms of service</NavLink></span></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="downloadPageSecondSectionStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5 secondSectionIosCard">
                            <p>Mobile</p>
                            <h5>iOS</h5>
                            <img src={DownloadTwo} alt="First Image" className='img-fluid' onClick={() => window.open("https://apps.apple.com/app/id6450197341")} />
                        </div>
                        <div className="col-md-5 secondSectionAndroidCard">
                            <p>Mobile</p>
                            <h5>Android</h5>
                            <img src={DownloadOne} alt="Second Image" className='img-fluid' onClick={() => window.open("https://play.google.com/store/apps/details?id=com.nexuschatapp.app")} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="downloadPageThirdSectionStartHere">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-md-5 downloadPageIosButton">
                            <button><img src={DownloadThree} alt="First Image" className='img-fluid' onClick={() => window.open("https://apps.apple.com/app/id6450197341")} /></button>
                        </div>
                        <div className="col-md-5 downloadPageAndroidButton">
                            <button><img src={DownloadFour} alt="Second Image" className='img-fluid' onClick={() => window.open("https://play.google.com/store/apps/details?id=com.nexuschatapp.app")} /></button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DownloadPage