import React from 'react'
import { Helmet } from 'react-helmet'
import SecondBlog from "../../Images/Blogs_Page/blog_2.png"
import "../CSS/ForAllIndividualBlog.css"

const BlogPageBlog = () => {
  return (
    <>
      <Helmet>
        <title>Blog Page | Nexus Chatapp</title>
        {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
        <link rel="canonical" href="https://www.nexuschatapp.com/blogs/blog-page" />
      </Helmet>
      <section className="blogIndividualPageSectionStartHere">
        <div className="container blogIndividualPageInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12 blogIndividualImageHeadingAndParaDiv">
              <img src={SecondBlog} alt="Second Blog" className='img-fluid' />
              <article className='blogsNumberOneArticleSection'>
                <h1>Blog Page</h1>
                <p>The Nexus software program allows users to communicate with other users in real-time. These types of applications have become increasingly popular in recent years, particularly with the proliferation of smartphones and the rise of social media.</p>
                <h2>Privacy is enhanced, and more control over the Groups and group members</h2>
                <p>Our goal is to act as a bridge between customer and business in which a third party is involved. We recognized the need for more control over the end customers and to ensure the private chat with the customers.</p>
                <p>In Nexus, we provide more controls, like hiding the names of the individual customers, and the admin can also hide the contact numbers of the group members.</p>
                <h2>Be part of anonymous groups without sharing identity</h2>
                <p>Customers can be a part of the anonymous groups without any fear about their privacy and even without sharing their original identity.</p>
                <h2>Create groups with friends in your contact list and Send messages to friends privately</h2>
                <p>In a group of friends, one can easily create a group in your contact list, and you can message in the group without disclosing your identity so that you can express your thoughts and feelings without disclosing your identity.</p>
                <p>You can easily share any documents, pictures, and videos in the group, and the admin will be authorized to restrict who can send these messages.</p>
                <p>Chatting on Nexus is carefree with end-to-end encryption.</p>
                <h2>Features in Private Chat groups in Nexus:</h2>
                <h3>Enhanced admin controls:</h3>
                <p>The group admins can add new participants to the group.</p>
                <p>The group admins can decide whether the names and contact of the participants are displayed on the group.</p>
                <p>The group admins can alter the subject, description, and also admin can remove any participant from the group if the chat is inappropriate.</p>
                <h3>Group description</h3>
                <p>The group admin can add a short description that can mention the guidelines to be followed and the purpose of the group.</p>
                <h3>How To Create The Private Groups</h3>
                <p>Open Nexus &gt; More options &gt; New group &gt; Select contacts &gt; Enable Private group &gt; Click edit icon &gt; Edit Names of the Participants &gt; Edit Group Name and Description &gt; New Private Group is created.</p>
                <p>With the invitation link, you can add more participants.</p>
                <h3>Features in Public Chat groups in Nexus</h3>
                <p><b>Group chat</b>: This allows group members to communicate with one another in real time.File sharing: This allows users to share images, documents, and other types of files with the group.</p>
                <p><b>User profiles</b>: Each user in the group may have their own profile, which may include information such as their name, profile picture, and contact information.</p>
                <p><b>Notifications</b>: Users may be notified when a new message is received, or someone mentions them.</p>
                <h3>How To Create The Public Group</h3>
                <p>Open Nexus &gt; More options &gt; New group &gt; Select contacts &gt; Disable Private group &gt; Edit Group Name and Description &gt; New Public Group is created.</p>
                <p>With the invitation link, you can add more participants.</p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogPageBlog;