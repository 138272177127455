import React from 'react';
import { Helmet } from 'react-helmet';
import "../CSS_File/ContactUsPage.css";

const ContactUsPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us | Nexus Chatapp</title>
                {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
                <link rel="canonical" href="https://www.nexuschatapp.com/contactUs" />
            </Helmet>
            <section className="nexusContactUsPageStartHere">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-md-12 contactUsPageContent">
                            <h1>Contact Nexus</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efedtive neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.</p>
                        </div>
                        <div className="col-md-5 formDiv">
                            <form name='contact-us' method="post" enctype="multipart/form-data" data-netlify="true" onSubmit="submit">
                                <input type="hidden" name='form-name' value="contact-us" />
                                <div className="phoneNumberDiv mb-4">
                                    <label htmlFor="phoneNumber" className='phoneNumberLabel form-label'>Phone Number</label><br />
                                    <input type="text" id='phoneNumber' className='phoneNumberCodeInput' name='countryCode' placeholder='+91' />
                                    <input type="number" id='phoneNumber' className='phoneNumberInput ms-4' name='phoneNumber' placeholder='Enter phone number' />
                                </div>
                                <div className="emailAddressDiv mb-4">
                                    <label htmlFor="emailAddress" className='phoneNumberLabel form-label'>Email</label>
                                    <input type="email" id='emailAddress' className='emailAddressInput form-control' name='emailAddress' placeholder='Enter email' />
                                </div>
                                <div className="messageDiv mb-4">
                                    <label htmlFor="message" className='messageLabel form-label'>Message</label>
                                    <textarea rows="3" id='message' className='messageInput form-control' name='message'></textarea>
                                </div>
                                <button className="sendQueryBtn">Send Query</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUsPage;