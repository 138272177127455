import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Component/JS_File/Footer';
import Navbar from './Component/JS_File/Navbar';
import BlogsCardMainPage from './Pages/JSX_File/BlogsCardMainPage';
import FeaturesPage from './Pages/JSX_File/FeaturesPage';
import LandingPage from './Pages/JSX_File/LandingPage';
import PrivacyPage from './Pages/JSX_File/PrivacyPage';
// import BlogsPage from './Pages/JSX_File/BlogsPage';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import BlogNumberOne from './BlogsIndividualPage/JSX/BlogNumberOne';
import BlogNumberTwo from './BlogsIndividualPage/JSX/BlogNumberTwo';
import BlogPageBlog from './BlogsIndividualPage/JSX/BlogPageBlog';
import UseCaseBlog from './BlogsIndividualPage/JSX/UseCaseBlog';
import ScrollToTop from './Component/JS_File/ScrollToTop';
import DownloadAndInstallation from './Pages/HelpsInnerPage/JSX/DownloadAndInstallation';
import AboutUsPage from './Pages/JSX_File/AboutUsPage';
import ContactUsPage from './Pages/JSX_File/ContactUsPage';
import DownloadPage from './Pages/JSX_File/DownloadPage';
import HelpPage from './Pages/JSX_File/HelpPage';
import PrivacyPolicyPage from './Pages/JSX_File/PrivacyPolicyPage';
import TermsOfServicesPage from './Pages/JSX_File/TermsOfServicesPage';


const tagManagerArgs = {
  gtmId: 'G-RXKWSNMLJY'
}
TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Helmet>
          <title>Nexus Chatapp</title>
          {/* <meta name="description" content="The best dissertation writing services to hire. It is the perfect choice for anyone who wants help writing their dissertation quickly and correctly. Hire us!!" /> */}
          <link rel="canonical" href="https://www.nexuschatapp.com/" />
        </Helmet>
        <div>
          <Routes>
            <Route exact path={"/"} element={<LandingPage />} />
            <Route exact path={"/features"} element={<FeaturesPage />} />
            <Route exact path={"/privacy"} element={<PrivacyPage />} />
            <Route exact path={"/blogs"} element={<BlogsCardMainPage />} />
            <Route exact path={"/download"} element={<DownloadPage />} />
            <Route exact path={"/help/"} element={<HelpPage />} >
              <Route exact path="downloadAndInstallation" element={<DownloadAndInstallation />} />
            </Route>
            {/* Blog Page Start Here */}
            <Route exact path={"/blogs/use-case"} element={<UseCaseBlog />} />
            <Route exact path={"/blogs/blog-page"} element={<BlogPageBlog />} />
            <Route exact path={"/blogs/blog-number-one"} element={<BlogNumberOne />} />
            <Route exact path={"/blogs/blog-number-two"} element={<BlogNumberTwo />} />
            {/* Blog Page End Here */}
            <Route exact path={"/aboutUs"} element={<AboutUsPage />} />
            <Route exact path={"/contactUs"} element={<ContactUsPage />} />
            <Route exact path={"/privacyPolicy"} element={<PrivacyPolicyPage />} />
            <Route exact path={"/termsOfService"} element={<TermsOfServicesPage />} />
            {/* 404 Page */}
            <Route exact path={"*"} element={<LandingPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
      {/* <Features_Page /> */}
    </>
  );
}

export default App;
